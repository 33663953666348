import el from './locales/el.json'
import en from './locales/en.json'

export default {
  locale: 'el',
  fallbackLocale: 'el',
  messages: {
    en,
    el,
  },
  fallbackWarn: false,
  missingWarn: false,
  vueI18nLoader: true,
}
